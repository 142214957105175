import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { Hero, Layout, Seo, TextContent } from "../components"
import * as textStyles from "../components/text-content.module.scss"

export default function WieIsWim({ data }) {
  var heroImage = getImage(data.file)
  var title = "Wie is Wim?"

  return (
    <Layout site="audio" page={`/wieiswim`}>
      <Seo title={title} shop="audio" />
      <Hero image={heroImage} site="audio">
        {title}
      </Hero>
      <TextContent
        title={title}
        span="Wim kent de wereld als zoon van een globe-trottende militair. Kongo, Canada, Duitsland. Wim heeft er gewoond."
      >
        <p className={textStyles.paragraph}>
          Oorspronkelijk was zijn droom om terug te keren naar Afrika, maar de
          liefde heeft er anders over beslist. Wim vestigde zich in 2010 als
          zelfstandig audioloog bij Optiek Freija. Vanaf 2018 vind je hem in de
          nieuwe accommodatie, naast de Optiekzaak van zijn echtgenote.
        </p>
        <p className={textStyles.paragraph}>
          In Wim's hoorcenter informeert hij je met kennis van zaken over de
          recentste technieken en trends en helpt hij je met het kiezen van de
          best passende hoor-oplossing. Hij combineert zijn professionalisme met
          een grote onafhankelijkheid en gedrevenheid.
        </p>
        <p className={textStyles.paragraph}>
          Wie ooit al bij Wim's hoorcenter is langs geweest zal het zeker
          bevestigen. Wim is je vertrouwen meer dan waard. Hij blijft steeds
          begaan met jouw welzijn en maakt er een erezaak van om jouw gehoor op
          te volgen en te verhelpen, waar nodig. Wim's Hoorcenter is ook vrij
          toegankelijk voor minder mobiele patiënten. Wim reikt een helpende
          hand uit, om iedere persoon te begeleiden. Ook daar maakt Wim het
          verschil.
        </p>
        <p className={textStyles.paragraph}>
          Wim staat als actief persoon, die graag reist en duikt, stevig in het
          leven. Zijn motto? Jouw vraag is mijn uitdaging. Bij Wim zijn je oren
          in veilige handen.
        </p>
      </TextContent>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    file(name: { eq: "wieiswim-banner" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, width: 1920)
      }
    }
  }
`
